import React from "react";
import { TbTruckDelivery } from "react-icons/tb";
import { MdVerified, MdVerifiedUser } from "react-icons/md";
import ban1 from "../../../assets/SliderIMG/ban1.jpg";
import ban2 from "../../../assets/SliderIMG/ban2.jpg";
import ban3 from "../../../assets/SliderIMG/ban3.jpg";
import ban4 from "../../../assets/SliderIMG/ban4.jpg";
import assets310 from "../../../assets/images/Asset310.png";
import assets41 from "../../../assets/images/Asset41.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const PageOne = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <>
      <Slider
        {...settings}
        className="slick-slider"
        style={{
          background: "linear-gradient(90deg, #60ACF8 0%, #0073E6 100%)",
          width: "100%",
          overflow: "hidden",
          marginTop: "0px", // Added top margin
          marginBottom: "0px", // Added bottom margin
        }}
      >
        <div>
          <Link to="/products">
            <img
              src={ban1}
              alt="Slide 1"
              className="w-full h-auto object-cover cursor-pointer"
              style={{
                width: "100%",
                height: "auto", // Makes it responsive
                maxHeight: "400px", // Fixed max height for the banner
                objectFit: "cover", // Ensures the image covers the space without stretching
              }}
            />
          </Link>
        </div>
        <div>
          <Link to="/products">
            <img
              src={ban2}
              alt="Slide 2"
              className="w-full h-auto object-cover cursor-pointer"
              style={{
                width: "100%",
                height: "auto", // Makes it responsive
                maxHeight: "400px", // Fixed max height for the banner
                objectFit: "cover", // Ensures the image covers the space without stretching
              }}
            />
          </Link>
        </div>
        <div>
          <Link to="/products">
            <img
              src={ban3}
              alt="Slide 3"
              className="w-full h-auto object-cover cursor-pointer"
              style={{
                width: "100%",
                height: "auto", // Makes it responsive
                maxHeight: "400px", // Fixed max height for the banner
                objectFit: "cover", // Ensures the image covers the space without stretching
              }}
            />
          </Link>
        </div>
        <div>
          <Link to="/products">
            <img
              src={ban4}
              alt="Slide 4"
              className="w-full h-auto object-cover cursor-pointer"
              style={{
                width: "100%",
                height: "auto", // Makes it responsive
                maxHeight: "400px", // Fixed max height for the banner
                objectFit: "cover", // Ensures the image covers the space without stretching
              }}
            />
          </Link>
        </div>
      </Slider>

   <div
  style={{
    background: "linear-gradient(90deg, #60ACF8 0%, #0073E6 100%)",
  }}
  className="text-white pl-3 sm:pl-3 pt-3 pb-4 w-full overflow-hidden"
>
  <button className="bg-white text-blue-500 font-bold py-2 px-4 rounded-full hover:bg-blue-500 hover:text-white mx-2 sm:mx-8 mb-3 sm:mb-6">
    <Link to={"/products"}>Shop Now</Link>
  </button>
</div>


      <div
        style={{
          background: "linear-gradient(90deg, #60ACF8 0%, #0073E6 100%)",
        }}
        className="text-white relative w-full overflow-hidden"
      >
      </div>

    </>
  );
};

export default PageOne;
