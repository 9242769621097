import React, { useState, useEffect } from "react";
import easypaisa from "../../assets/images/EasypaisaAppLogoVector1.png";
import jazcash from "../../assets/images/Dae7998E4Ddfb250B1646B3Bf6290C61.png";
import facebook from "../../assets/images/VecteezyFacebookLogoPngFacebookIconTransparentPng189306981.png";
import instagram from "../../assets/images/VecteezyInstagramLogoPngInstagramIconTransparent189304151.png";
import { Link } from "react-router-dom";
import LoadingPage from "../../LoadingPage";

const BottomBar = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <>
      <footer className="bg-gray-700 text-white py-10">
        <div className="container mx-auto grid grid-cols-1  lg:grid-cols-4 gap-12 px-5 ml-5 mr-5">
          {/* Customer Care */}
          <div>
            <h3 className="text-2xl font-semibold mb-4">Customer Care</h3>
            <ul className="space-y-2">

              <li>
                <Link
                  to="/contact-us"
                  className="hover:text-yellow-300 transition-colors"
                >
                  Contact Us
                </Link>
              </li>
              {/* <li>
                <a
                  href="/sellerLoginPage"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-yellow-300 transition-colors"
                >
                  Seller Account
                </a>
              </li> */}
              <li>
                <Link
                  to="/sellerLoginPage"
                  className="hover:text-yellow-300 transition-colors"
                >
                  Seller Account
                </Link>
              </li>

              <li>
                <Link
                  to="/products"
                  className="hover:text-yellow-300 transition-colors"
                >
                  Dehleze Shoping
                </Link>
              </li>

            </ul>
          </div>

          {/* Dehleze Info */}
          <div>
            <h3 className="text-2xl font-semibold mb-4">Dehleze</h3>
            <ul className="space-y-2">
              <li>
                <Link
                  to="/about"
                  className="hover:text-yellow-300 transition-colors"
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="/privacy"
                  className="hover:text-yellow-300 transition-colors"
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  to="term"
                  className="hover:text-yellow-300 transition-colors"
                >
                  Terms & Conditions
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4">Payment Methods</h3>
            <div className="flex space-x-4 items-center justify-start">
              <img
                src={easypaisa}
                alt="Easypaisa"
                className="w-16 h-16 rounded-lg object-cover hover:scale-110 transition-transform"
              />
              <img
                src={jazcash}
                alt="JazzCash"
                className="w-12 h-12 rounded-lg object-cover hover:scale-110 transition-transform"
              />
            </div>
          </div>




          {/* <div>
            <h3 className="text-2xl font-semibold mb-4 ml-5">Payment Methods</h3>
            <div className="flex flex-wrap  mb-5 justify-center  mr-16">
              <img
                src={easypaisa}
                alt="Easypaisa"
                className="w-32 hover:scale-105 transition-transform object-contain ml-0"
              />
              <img
                src={jazcash}
                alt="JazzCash"
                className="w-12 hover:scale-105 rounded-[5px] transition-transform object-contain"
              />
            </div>
          </div> */}


          {/* Exclusive Deals */}
          <div>
            <h3 className="text-2xl font-semibold mb-2">Follow Us</h3>
            <div className="flex space-x-4">
              <a
                href="https://www.facebook.com/dehleze9"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={facebook}
                  alt="Facebook"
                  className="w-12 hover:scale-110 transition-transform"
                />
              </a>
              <a
                href="https://www.instagram.com/dehleze9/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={instagram}
                  alt="Instagram"
                  className="w-12 hover:scale-110 transition-transform"
                />
              </a>
            </div>
          </div>
        </div>

      </footer>

      <div className="text-center text-white bg-gradient-to-r from-blue-500 to-blue-800 text-xl  py-3">
        © {new Date().getFullYear()} Dehleze. All rights reserved.
      </div>

    </>
  );
};

export default BottomBar;
