import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useShoppingCart } from "../../Context/ShoppingCartContext";

const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [selectedColor, setSelectedColor] = useState("");
  const [quantity, setQuantity] = useState(1);
  const { addToCart, notification } = useShoppingCart();
  const imageRef = useRef(null);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(`http://39.61.51.195:8004/product/${id}`);
        setProduct(response.data);
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    fetchProductDetails();
  }, [id]);

  const handleAddToCart = () => {
    if (product) {
      addToCart({ ...product, selectedColor, quantity });
    }
  };

  const handleBuyNow = () => {
    alert("Redirecting to checkout...");
  };

  const handleColorSelect = (color) => {
    setSelectedColor(color);
  };

  const handleQuantityChange = (change) => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity + change));
  };

  const handleMouseMove = (e) => {
    const container = e.currentTarget;
    const rect = container.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;

    if (imageRef.current) {
      imageRef.current.style.transformOrigin = `${x}% ${y}%`;
    }
  };

  const handleMouseLeave = () => {
    if (imageRef.current) {
      imageRef.current.style.transformOrigin = "center center";
      imageRef.current.style.transform = "scale(1)";
    }
  };

  const handleMouseEnter = () => {
    if (imageRef.current) {
      imageRef.current.style.transform = "scale(2)";
    }
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === product.images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? product.images.length - 1 : prevIndex - 1
    );
  };

  if (!product) return <p>Loading...</p>;

  const totalPrice = product.price * quantity;

  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="flex flex-col items-center">
          {/* Main Image with Zoom on Hover */}
          <div
            className="relative w-full max-w-md h-[300px] overflow-hidden rounded-lg shadow-lg"
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            onMouseEnter={handleMouseEnter}
          >
            <img
              src={product.images[currentImageIndex]?.image}
              alt={product.name}
              className="w-full h-full object-contain transition-transform duration-300"
              ref={imageRef}
            />
            {/* Navigation Arrows */}
            <button
              className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white p-3 rounded-full hover:bg-gray-800 transition text-xl font-bold"
              onClick={handlePrevImage}
            >
              &#60;
            </button>
            <button
              className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white p-3 rounded-full hover:bg-gray-800 transition text-xl font-bold"
              onClick={handleNextImage}
            >
              &#62;
            </button>
          </div>

          {/* Thumbnail Images */}
          <div className="flex justify-center flex-wrap mt-4">
            {product.images.map((img, index) => (
              <img
                key={index}
                src={img.image}
                alt={`View ${index + 1}`}
                onClick={() => setCurrentImageIndex(index)}
                className={`w-16 h-16 object-cover m-1 rounded-md cursor-pointer border ${
                  index === currentImageIndex ? "border-blue-500" : "border-gray-300"
                } hover:border-blue-500 transition-transform duration-200 hover:scale-105`}
              />
            ))}
          </div>
        </div>

        <div>
          <h1 className="text-3xl font-bold mb-4">{product.name}</h1>
          <p className="text-gray-600 mb-4">{product.description}</p>
          <p className="text-2xl font-bold mb-4">Rs. {totalPrice}</p>

          {/* Quantity Selector */}
          <div className="flex items-center mb-4">
            <h3 className="font-semibold text-gray-700 mr-4">Quantity:</h3>
            <button
              onClick={() => handleQuantityChange(-1)}
              className="px-2 py-1 border border-gray-400 rounded-l-md bg-white hover:bg-gray-300"
            >
              -
            </button>
            <span className="px-4 py-1 border-t border-b border-gray-400">{quantity}</span>
            <button
              onClick={() => handleQuantityChange(1)}
              className="px-2 py-1 border border-gray-400 rounded-r-md bg-white hover:bg-gray-300"
            >
              +
            </button>
          </div>

          {/* Color Options */}
          <div className="mb-4">
            <h3 className="font-semibold text-gray-700 mb-2">Colors:</h3>
            <div className="flex gap-4">
              {product.colors?.map((color) => (
                <button
                  key={color.name}
                  onClick={() => handleColorSelect(color.name)}
                  className={`w-12 h-12 rounded-full border-2 ${
                    selectedColor === color.name ? "border-black" : "border-gray-300"
                  }`}
                  style={{ backgroundColor: color.hex }}
                >
                  <span className="sr-only">{color.name}</span>
                </button>
              ))}
            </div>
          </div>

          <div className="space-y-4">
            <button
              onClick={handleBuyNow}
              className="w-full py-3 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200"
            >
              Buy Now
            </button>
            <button
              onClick={handleAddToCart}
              className="w-full py-3 bg-green-500 text-white rounded hover:bg-green-600 transition duration-200"
            >
              Add to Cart
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
