import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useOrderContext } from "../../../Components/Pages/Products/Oders/OrderContext";
import { useShoppingCart } from "../../../Context/ShoppingCartContext";

const OnepageProduct = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [error, setError] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [selectedColor, setSelectedColor] = useState("");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const imageRef = useRef(null);
  const navigate = useNavigate();

  const { addToCart, notification } = useShoppingCart();
  const { userData } = useOrderContext();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(
          `http://39.61.51.195:8004/product/${productId}/`
        );
        setProduct(response.data);
      } catch (err) {
        setError("Product not found");
      }
    };
    fetchProduct();
  }, [productId]);

  // Handle image zoom on hover
  const handleMouseMove = (e) => {
    const container = e.currentTarget;
    const rect = container.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;

    if (imageRef.current) {
      imageRef.current.style.transformOrigin = `${x}% ${y}%`;
    }
  };

  const handleMouseLeave = () => {
    if (imageRef.current) {
      imageRef.current.style.transformOrigin = "center center";
      imageRef.current.style.transform = "scale(1)";
    }
  };

  const handleMouseEnter = () => {
    if (imageRef.current) {
      imageRef.current.style.transform = "scale(2)";
    }
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === product.images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? product.images.length - 1 : prevIndex - 1
    );
  };

  const handleQuantityChange = (change) => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity + change));
  };

  const totalPrice = product ? product.price * quantity : 0;

  // Handle color selection
  const handleColorSelect = (color) => {
    setSelectedColor(color);
  };

  // Handle add to cart
  const handleAddToCart = () => {
    if (userData) {
      addToCart({ ...product, selectedColor, quantity });
    } else {
      navigate("/userLogin");
    }
  };

  // Handle buy now
  const handleBuyNow = () => {
    if (userData) {
      navigate("/createOrder");
    } else {
      navigate("/userLogin");
    }
  };

  // Dummy Reviews
  const reviews = [
    {
      id: 1,
      user: "John Doe",
      rating: 4,
      comment: "Great product! Really loved the quality and color options.",
    },
    {
      id: 2,
      user: "Jane Smith",
      rating: 5,
      comment: "Excellent product, exceeded my expectations. Highly recommend!",
    },
    {
      id: 3,
      user: "Alice Johnson",
      rating: 3,
      comment: "Good product, but the delivery was a bit slow.",
    },
  ];

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <h1 className="text-2xl font-bold text-red-500">{error}</h1>
      </div>
    );
  }

  if (!product) {
    return <div className="text-center mt-10">Loading product details...</div>;
  }

  return (
    <div className="max-w-screen-xl mx-auto p-4 sm:p-6 flex flex-col lg:flex-row">
      {/* Image Section */}
      <div className="lg:w-1/2 mb-6 lg:mb-0">
        <div
          className="relative w-full max-w-xs sm:max-w-md lg:max-w-lg h-[300px] sm:h-[400px] lg:h-[500px] overflow-hidden rounded-lg shadow-lg ml-4"
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          onMouseEnter={handleMouseEnter}
        >
          <img
            src={product.images[currentImageIndex]?.image}
            alt={product.name}
            className="w-full h-full object-contain transition-transform duration-300"
            ref={imageRef}
          />
          <button
            className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white p-3 rounded-full hover:bg-gray-800 transition z-20 text-xl font-bold"
            onClick={handlePrevImage}
          >
            &#60;
          </button>
          <button
            className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white p-3 rounded-full hover:bg-gray-800 transition z-20 text-xl font-bold"
            onClick={handleNextImage}
          >
            &#62;
          </button>
        </div>

        {/* Thumbnails */}
        <div className="flex justify-center mt-4 flex-wrap gap-2">
          {product.images.map((img, index) => (
            <div key={index} className="p-1">
              <img
                src={img.image}
                alt={`View ${index + 1}`}
                onClick={() => setCurrentImageIndex(index)}
                className={`w-16 h-16 sm:w-20 sm:h-20 object-cover m-1 rounded-md cursor-pointer border ${
                  index === currentImageIndex ? "border-blue-500" : "border-gray-300"
                } hover:border-blue-500 transition-transform duration-200 hover:scale-105`}
              />
            </div>
          ))}
        </div>
      </div>

      {/* Product Details Section */}
      <div className="lg:w-1/2 pl-4 sm:pl-6">
        <h1 className="text-3xl sm:text-4xl font-bold text-gray-800">{product.name}</h1>
        <p className="text-gray-500 mt-4 text-sm sm:text-base">{product.description}</p>

        {/* Total Price */}
        <div className="mt-4 text-lg sm:text-xl font-semibold text-gray-800">
          Total Price: Rs. {totalPrice.toFixed(2)}
        </div>

        {/* Quantity Selector */}
        <div className="flex items-center mt-6">
          <button
            className="px-4 py-2 bg-gray-300 text-xl rounded-full hover:bg-gray-400 transition"
            onClick={() => handleQuantityChange(-1)}
          >
            -
          </button>
          <span className="mx-4 text-xl">{quantity}</span>
          <button
            className="px-4 py-2 bg-gray-300 text-xl rounded-full hover:bg-gray-400 transition"
            onClick={() => handleQuantityChange(1)}
          >
            +
          </button>
        </div>

        {/* Color Selector */}
        {product.colors && (
          <div className="mt-6">
            <p className="font-semibold text-lg">Select Color:</p>
            <div className="flex gap-4 mt-2">
              {product.colors.map((color) => (
                <button
                  key={color}
                  onClick={() => handleColorSelect(color)}
                  className={`w-12 h-12 rounded-full border-2 ${
                    selectedColor === color ? "border-black" : "border-gray-300"
                  }`}
                  style={{ backgroundColor: color }}
                >
                  <span className="sr-only">{color}</span>
                </button>
              ))}
            </div>
          </div>
        )}

        <div className="mt-6 flex gap-4 flex-col sm:flex-row">
          <button
            onClick={handleBuyNow}
            className="w-full sm:w-1/2 bg-blue-500 text-white py-3 px-6 rounded-lg text-lg hover:bg-blue-600 transition"
          >
            Buy Now
          </button>
          <button
            onClick={handleAddToCart}
            className="w-full sm:w-1/2 bg-green-500 text-white py-3 px-6 rounded-lg text-lg hover:bg-green-600 transition"
          >
            Add to Cart
          </button>
        </div>

        {/* Reviews Section */}
        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-gray-800">Customer Reviews</h2>
          {reviews.map((review) => (
            <div key={review.id} className="mb-4 p-4 border-b border-gray-300">
              <div className="flex items-center">
                <p className="font-semibold">{review.user}</p>
                <span className="ml-2 text-yellow-500">
                  {"★".repeat(review.rating)}
                </span>
              </div>
              <p className="text-gray-700 mt-2">{review.comment}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OnepageProduct;
