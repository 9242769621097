import React, { useContext, useState, useEffect } from "react";
import { SellerContext } from "../../Accounts/SellerContext";
import LoadingPage from "../../../../LoadingPage";


const Profile = () => {
    const { seller, logout } = useContext(SellerContext);

    useEffect(() => {
        if (seller) {
          console.log("Seller Data in Dashboard", seller);
        }
      }, [seller]);
    
      if (!seller) {
        return <LoadingPage />;
      }
  return (
    <>
        <div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-sm text-gray-600">Name:</p>
                <p className="text-lg font-medium">{seller.seller.first_name || "N/A"} {seller.seller.last_name || "N/A"}</p>
              </div>
              <div>
                <p className="text-sm text-gray-600">Seller ID:</p>
                <p className="text-lg font-medium">{seller.seller.id || "N/A"}</p>
              </div>
              <div>
                <p className="text-sm text-gray-600">Email:</p>
                <p className="text-lg font-medium">{seller.seller.email || "N/A"}</p>
              </div>
              <div>
                <p className="text-sm text-gray-600">Phone Number:</p>
                <p className="text-lg font-medium">{seller.seller.phone_number || "N/A"}</p>
              </div>
            </div>
          </div>
    </>
  )
}

export default Profile
