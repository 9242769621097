import React, { createContext, useState } from 'react';

export const SellerContext = createContext();

export const SellerProvider = ({ children }) => {
  const [seller, setSeller] = useState(null);
  const [totalAmount, settotalAmount] = useState(null);
  const [totalOrder, settotalOrder] = useState(null);

  // Define the logout function
  const logout = () => {
    // Logic to handle logout, for example:
    // - Clear user data from state
    // - Optionally remove tokens from localStorage/sessionStorage
    setSeller(null); // Reset the seller to null
  };

  return (
    <SellerContext.Provider value={{ seller, setSeller, logout, totalOrder, settotalOrder, totalAmount, settotalAmount }}>
      {children}
    </SellerContext.Provider>
  );
};
