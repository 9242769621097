import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { FaHeart, FaRegHeart, FaCartPlus } from "react-icons/fa";
import { AiOutlineEye } from "react-icons/ai";
import ProductDetails from "./ProductDetails"; // Ensure this path is correct
import LoadingPage from "../../LoadingPage"; // Import your loading page component

// Utility to capitalize first letters
const capitalizeFirstLetter = (string) => {
  return string.replace(/\b\w/g, (char) => char.toUpperCase());
};

const CategoryBarProducts = () => {
  const { categoryId } = useParams();
  const [products, setProducts] = useState([]);
  const [favorites, setFavorites] = useState({});
  const [cart, setCart] = useState([]);
  const [hoveredProductId, setHoveredProductId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategoryProducts = async () => {
      try {
        const response = await axios.get(`http://39.61.51.195:8004/products/`);
        const filteredProducts = response.data.filter(
          (product) => product.category_Id === parseInt(categoryId) // Compare categoryId as number
        );
        setProducts(filteredProducts);
      } catch (error) {
        setError("Error fetching products");
      } finally {
        setLoading(false);
      }
    };

    fetchCategoryProducts();
  }, [categoryId]);

  // Handlers
  const handleFavoriteToggle = useCallback((id) => {
    setFavorites((prevFavorites) => ({
      ...prevFavorites,
      [id]: !prevFavorites[id],
    }));
  }, []);

  const handleAddToCart = useCallback((product) => {
    setCart((prevCart) => [...prevCart, product]);
  }, []);

  const handlePreview = useCallback(
    (productId) => {
      navigate(`/products/${productId}`); // Navigate to the product detail page
    },
    [navigate]
  );

  const handleMouseEnter = useCallback((id) => {
    setHoveredProductId(id);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHoveredProductId(null);
  }, []);

  const handleImageClick = (productId) => {
    navigate(`/products/${productId}`); // Navigate to product detail page
  };

  if (loading) return <LoadingPage />;
  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="p-6" style={{ fontFamily: "Futura, sans-serif" }}>
      <section className="col-span-9 ml-4 mr-4">
        {products.length > 0 ? (
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6">
            {products.map((product) => (
              <div
                key={product.id}
                className="relative bg-white shadow-lg rounded-lg overflow-hidden cursor-pointer transform transition duration-300 hover:scale-105 hover:shadow-2xl group"
                onMouseEnter={() => handleMouseEnter(product.id)}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleImageClick(product.id)}
              >
                {/* Product Image */}
                <div className="relative w-full h-45 flex items-center justify-center">
                  <img
                    src={
                      hoveredProductId === product.id && product.images.length > 1
                        ? product.images[1].image
                        : product.images.length > 0
                        ? product.images[0].image
                        : "placeholder.jpg"
                    }
                    alt={product.name}
                    className="w-full h-full object-cover rounded-t-lg transition-all duration-300 ease-in-out"
                  />
                  {/* Hover Icons */}
                  {hoveredProductId === product.id && (
                    <div className="absolute top-2 right-2 w-10 flex flex-col items-center space-y-4 bg-white p-2 rounded-lg shadow-lg">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleAddToCart(product);
                        }}
                        className="text-blue-600 hover:text-blue-800"
                      >
                        <FaCartPlus size={20} />
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handlePreview(product.id); // Navigate to product detail page
                        }}
                        className="text-blue-600 hover:text-blue-800"
                      >
                        <AiOutlineEye size={20} />
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleFavoriteToggle(product.id);
                        }}
                        className={`${
                          favorites[product.id] ? "text-red-500" : "text-gray-300"
                        } hover:text-red-700`}
                      >
                        {favorites[product.id] ? <FaHeart /> : <FaRegHeart />}
                      </button>
                    </div>
                  )}
                </div>

                {/* Product Details */}
                <div className="p-4">
                  <div className="flex justify-between items-center mb-2">
                    <h2 className="font-semibold text-gray-800 line-clamp-1 text-lg">
                      {capitalizeFirstLetter(product.name)}
                    </h2>
                  </div>
                  <div className="flex items-center justify-between mb-4">
                    <p className="text-gray-800 font-bold text-lg">Rs. {product.price}</p>
                    <p className="text-gray-500 line-through text-sm">
                      {(product.price * 1.05).toFixed(2)}
                    </p>
                  </div>
                  {/* Preview Button */}
                  {hoveredProductId === product.id && (
                    <div className="text-center">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handlePreview(product.id); // Navigate to product detail page
                        }}
                        className="w-full bg-blue-600 text-white font-semibold py-2 rounded-lg hover:bg-blue-700 transition duration-300"
                      >
                        Preview
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No products available in this category.</p>
        )}
      </section>
    </div>
  );
};

export default CategoryBarProducts;
