import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useOrderContext } from "../../../Components/Pages/Products/Oders/OrderContext";
import { useShoppingCart } from "../../../Context/ShoppingCartContext";

const ProductDetails = ({ product, onClose }) => {
  const { setProduct, userData } = useOrderContext();
  const { addToCart, notification } = useShoppingCart();
  const navigate = useNavigate();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [selectedColor, setSelectedColor] = useState("");
  const [quantity, setQuantity] = useState(1);
  const imageRef = useRef(null);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    setProduct(product);

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [product, setProduct]);

  const handleAddToCart = () => {
    if (userData) {
      addToCart({ ...product, selectedColor, quantity });
    } else {
      navigate("/userLogin");
    }
  };

  const handleBuyNow = () => {
    if (userData) {
      navigate("/createOrder");
    } else {
      navigate("/userLogin");
    }
  };

  const handleColorSelect = (color) => {
    setSelectedColor(color);
  };

  const handleMouseMove = (e) => {
    const container = e.currentTarget;
    const rect = container.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;

    if (imageRef.current) {
      imageRef.current.style.transformOrigin = `${x}% ${y}%`;
    }
  };

  const handleMouseLeave = () => {
    if (imageRef.current) {
      imageRef.current.style.transformOrigin = "center center";
      imageRef.current.style.transform = "scale(1)";
    }
  };

  const handleMouseEnter = () => {
    if (imageRef.current) {
      imageRef.current.style.transform = "scale(2)";
    }
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === product.images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? product.images.length - 1 : prevIndex - 1
    );
  };

  const handleQuantityChange = (change) => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity + change));
  };

  const totalPrice = product.price * quantity;

  if (!product) return null;

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50 p-6">
      <div className="bg-white w-full max-w-4xl rounded-md shadow-lg overflow-hidden relative">
        {/* Fixed Header */}
        <div className="flex justify-between items-center p-4 border-b sticky top-0 bg-white z-10">
          {notification && (
            <div
              className={`absolute top-0 left-0 w-full p-2 text-center rounded-t-md ${
                notification === "Product added successfully!" ? "bg-green-500" : "bg-red-500"
              } text-white`}
            >
              {notification}
            </div>
          )}
          <h2 className="text-xl font-bold flex-1 text-center">{product.name}</h2>
          <button onClick={onClose} className="text-red-500 font-bold text-3xl">
            &times;
          </button>
        </div>

        {/* Scrollable Content */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 p-6 max-h-[calc(100vh-140px)] overflow-y-auto">
          <div className="flex flex-col items-center">
            {/* Main Image with Zoom on Hover */}
            <div
              className="relative w-full max-w-xs h-[300px] overflow-hidden rounded-lg shadow-lg"
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
              onMouseEnter={handleMouseEnter}
            >
              <img
                src={product.images[currentImageIndex]?.image}
                alt={product.name}
                className="w-full h-full object-contain transition-transform duration-300"
                ref={imageRef}
              />
              {/* Navigation Arrows */}
              <button
                className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white p-3 rounded-full hover:bg-gray-800 transition z-20 text-xl font-bold"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent hover effects on the image
                  handlePrevImage();
                }}
              >
                &#60;
              </button>
              <button
                className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white p-3 rounded-full hover:bg-gray-800 transition z-20 text-xl font-bold"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent hover effects on the image
                  handleNextImage();
                }}
              >
                &#62;
              </button>
            </div>

            {/* Thumbnail Images */}
            <div className="flex justify-center flex-wrap mt-4">
              {product.images.map((img, index) => (
                <div key={index} className="p-1">
                  <img
                    src={img.image}
                    alt={`View ${index + 1}`}
                    onClick={() => setCurrentImageIndex(index)}
                    className={`w-16 h-16 object-cover m-1 rounded-md cursor-pointer border ${
                      index === currentImageIndex ? "border-blue-500" : "border-gray-300"
                    } hover:border-blue-500 transition-transform duration-200 hover:scale-105`}
                  />
                </div>
              ))}
            </div>
          </div>

          <div>
          <h2 className="text-xl font-bold flex-1 text-left">{product.name}</h2>
          
            <div className="text-gray-600 mb-4 text-xs md:text-sm max-h-[120px] overflow-y-scroll pt-2">

              {product.description}
            </div>
            
            <p className="text-gray-800 font-bold text-sm md:text-sm pt-2">
               Rs  {totalPrice.toFixed(2)}
            </p>
            {/* Quantity Selector */}
            <div className="flex items-center mt-4">
              <h3 className="font-semibold text-gray-700 mr-4 text-sm">QTY :</h3>
              
              <button
                onClick={() => handleQuantityChange(-1)}
                className="px-2 py-1 border border-gray-400 rounded-l-md bg-white hover:bg-gray-300"
              >
                -
              </button>
              <span className="px-4 py-1 border-t border-b border-gray-400">
                {quantity}
              </span>
              <button
                onClick={() => handleQuantityChange(1)}
                className="px-2 py-1 border border-gray-400 rounded-r-md bg-white hover:bg-gray-300"
              >
                +
              </button>
            </div>
              
            {/* Color Options */}
            <div className="mt-4">
              {/* <h3 className="font-semibold text-gray-700">Color</h3> */}
              <div className="flex flex-wrap gap-4 mt-2">
                {product.colors?.map((color) => (
                  <button
                    key={color.name}
                    onClick={() => handleColorSelect(color.name)}
                    className={`w-12 h-12 rounded-full border-2 ${
                      selectedColor === color.name ? "border-black" : "border-gray-300"
                    }`}
                    style={{ backgroundColor: color.hex }}
                  >
                    <span className="sr-only">{color.name}</span>
                  </button>
                ))}
              </div>
            </div>

            <div className="border-t-2 border-b-2 py-4 my-4">
              <p className="text-gray-700 text-sm md:text-base">
                <strong>Delivery:</strong> Cash On Delivery
              </p>
            </div>
            <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 mt-4">
              <button
                onClick={handleBuyNow}
                className="w-full py-3 bg-blue-500 text-white rounded hover:bg-blue-600 hover:shadow-lg transition duration-300 transform hover:scale-105"
              >
                Buy Now
              </button>
              <button
                onClick={handleAddToCart}
                className="w-full py-3 bg-red-500 text-white rounded hover:bg-red-600 hover:shadow-lg transition duration-300 transform hover:scale-105"
              >
                Add to Cart
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
