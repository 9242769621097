import React from "react";

const Billing = () => {
  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      {/* Header */}
      <header className="flex items-center justify-between mb-6">
        <h1 className="text-2xl font-bold">Billing</h1>
        <input
          type="text"
          placeholder="Search..."
          className="px-4 py-2 border rounded-lg text-sm"
        />
      </header>

      {/* Overview Section */}
      <section className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        {/* Credit Card */}
        <div className="p-6 bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded-lg shadow-lg">
          {/* Card Header */}
          <div className="flex items-center justify-between mb-4">
            <span className="text-xl font-semibold">💳</span>
            <span className="text-sm">WiFi</span>
          </div>
          {/* Card Number */}
          <div className="text-lg font-bold tracking-widest mb-4">
            4562 1122 4594 7852
          </div>
          {/* Card Holder & Expiry */}
          <div className="flex justify-between items-center text-sm">
            <div>
              <p className="uppercase text-gray-300">Card Holder</p>
              <p className="font-medium">Jack Peterson</p>
            </div>
            <div>
              <p className="uppercase text-gray-300">Expires</p>
              <p className="font-medium">11/22</p>
            </div>
          </div>
        </div>

        {/* Payment Methods */}
        <div className="p-6 bg-white rounded-lg shadow-md space-y-4">
          <div>
            <p className="text-gray-500 text-sm">Salary</p>
            <p className="text-lg font-bold">+ $2000</p>
          </div>
          <div>
            <p className="text-gray-500 text-sm">Paypal</p>
            <p className="text-lg font-bold">$455.00</p>
          </div>
        </div>

        {/* Invoices */}
        <div className="p-6 bg-white rounded-lg shadow-md">
          <h3 className="text-lg font-bold mb-4">Invoices</h3>
          <ul className="space-y-4">
            <li className="flex items-center justify-between">
              <p>March 1, 2020</p>
              <button className="text-blue-600 font-semibold">PDF</button>
            </li>
            <li className="flex items-center justify-between">
              <p>February 10, 2021</p>
              <button className="text-blue-600 font-semibold">PDF</button>
            </li>
            <li className="flex items-center justify-between">
              <p>April 5, 2020</p>
              <button className="text-blue-600 font-semibold">PDF</button>
            </li>
          </ul>
        </div>
      </section>

      {/* Billing Information and Transactions */}
      <section className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Billing Information */}
        <div className="p-6 bg-white rounded-lg shadow-md">
          <h3 className="text-lg font-bold mb-4">Billing Information</h3>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="font-bold">Oliver Liam</p>
                <p className="text-sm text-gray-500">Viking Burrito</p>
                <p className="text-sm text-gray-500">oliver@burrito.com</p>
              </div>
              <div className="flex space-x-2">
                <button className="text-red-500">Delete</button>
                <button className="text-blue-500">Edit</button>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div>
                <p className="font-bold">Lucas Harper</p>
                <p className="text-sm text-gray-500">Stone Tech Zone</p>
                <p className="text-sm text-gray-500">lucas@stone-tech.com</p>
              </div>
              <div className="flex space-x-2">
                <button className="text-red-500">Delete</button>
                <button className="text-blue-500">Edit</button>
              </div>
            </div>
          </div>
        </div>

        {/* Transaction History */}
        <div className="p-6 bg-white rounded-lg shadow-md">
          <h3 className="text-lg font-bold mb-4">Your Transactions</h3>
          <ul className="space-y-4">
            <li className="flex items-center justify-between">
              <div>
                <p className="font-bold">Netflix</p>
                <p className="text-sm text-gray-500">27 March 2020, 12:30 PM</p>
              </div>
              <p className="text-red-500">- $2500</p>
            </li>
            <li className="flex items-center justify-between">
              <div>
                <p className="font-bold">Apple</p>
                <p className="text-sm text-gray-500">27 March 2020, 04:30 AM</p>
              </div>
              <p className="text-green-500">+ $2000</p>
            </li>
            <li className="flex items-center justify-between">
              <div>
                <p className="font-bold">Stripe</p>
                <p className="text-sm text-gray-500">26 March 2020, 13:45 PM</p>
              </div>
              <p className="text-green-500">+ $750</p>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default Billing;
