import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useOrderContext } from "../../Pages/Products/Oders/OrderContext"; 
import LoadingPage from '../../../LoadingPage'

const Profile = () => {
  const { userData, setUserData } = useOrderContext();
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/userLogin");
        return;
      }

      try {
        const userResponse = await fetch(
          "http://39.61.51.195:8004/account/user",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (userResponse.ok) {
          const userData = await userResponse.json();
          setUserData(userData);

          const orderResponse = await fetch(
            "http://39.61.51.195:8004/account/create-order/",
            {
              method: "GET",
            }
          );

          if (orderResponse.ok) {
            const ordersData = await orderResponse.json();
            const filteredOrders = ordersData.filter(
              (order) => order.user === userData.id
            );
            setOrders(filteredOrders);
          } else {
            console.error("Failed to fetch orders");
          }

          const productResponse = await fetch(
            "http://39.61.51.195:8004/products/",
            {
              method: "GET",
            }
          );

          if (productResponse.ok) {
            const productsData = await productResponse.json();
            setProducts(productsData);
          } else {
            console.error("Failed to fetch products");
          }
        } else {
          console.error("Failed to fetch user data");
          navigate("/userLogin");
        }
      } catch (error) {
        console.error("Error:", error);
        navigate("/userLogin");
      }
    };

    fetchData();
  }, [navigate, setUserData]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setUserData(null);
    navigate("/userLogin");
  };

  if (!userData) {
    return <LoadingPage/>;
  }

  const getProductImage = (productId) => {
    const product = products.find((prod) => prod.id === productId);
    return product ?  product.images[0].image : null;
  };

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <div className="max-w-5xl mx-auto p-4">
        <div className="flex flex-col md:flex-row justify-between items-center mb-4 bg-white p-4 rounded-lg shadow">
          <h1 className="text-2xl md:text-3xl font-bold">Manage My Account</h1>
          <button
            onClick={handleLogout}
            className="mt-2 md:mt-0 bg-red-500 text-white px-4 py-2 rounded-lg"
          >
            Logout
          </button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          <div className="bg-white p-4 rounded-lg shadow">
            <h2 className="text-xl font-bold mb-2">Personal Profile</h2>
            <p>
              <strong>Name:</strong> {userData.name || "N/A"}
            </p>
            <p>
              <strong>Phone Number:</strong> {userData.phone_number || "N/A"}
            </p>
          </div>
          <div className="bg-white p-4 rounded-lg shadow">
            <h2 className="text-xl font-bold mb-2">Address Book</h2>
            <p>
              <strong>Name:</strong> {userData.name || "N/A"}
            </p>
            <p>
              <strong>Address:</strong> {userData.address || "N/A"}
            </p>
            <p>
              <strong>Phone:</strong> {userData.phone_number || "N/A"}
            </p>
          </div>
        </div>
        <div>
          <div className="overflow-x-auto">
            <h2 className="text-xl font-semibold rounded-tl-lg rounded-tr-lg bg-white pl-4 pt-2 pb-2">Recent Orders</h2>
            <table className="w-full">
              <thead>
                <tr className="bg-gray-100">
                  <th className="text-center p-2">Order#</th>
                  <th className="text-center p-2">Placed On</th>
                  <th className="text-center p-2">Total Price</th>
                  <th className="text-center p-2">Image</th>
                  <th className="text-center p-2">Order Status</th>
                </tr>
              </thead>
              <tbody>
                {orders.length > 0 ? (
                  orders.map((order, index) => (
                    <tr key={index} className="border bg-white">
                      <td className="text-center p-2 border-2 border-gray-5">{order.id || "N/A"}</td>
                      <td className=" p-2 border-2 border-gray-5 text-left">{order.address || "N/A"}</td>
                      <td className="text-center p-2 border-2 border-gray-5">Rs. {order.total_price || "N/A"}</td>
                      <td className="text-center p-2 border-2 border-gray-5">
                        <img
                          src={getProductImage(order.product)}
                          alt="Product"
                          className="h-16 w-16 object-cover mx-auto"
                        />
                      </td>
                      <td className="text-center p-2 border-2">
                        {order.order_status === "processing" && (
                          <span className="font-bold text-yellow-500">Under Processing</span>
                        )}
                        {order.order_status === "pending" && (
                          <span className="font-bold text-green-500">Pending</span>
                        )}
                        {order.order_status === "delivered" && (
                          <span className="text-blue-500 font-bold">Delivered</span>
                        )}
                        {order.order_status === "cancelled" && (
                          <span className="font-bold text-red-500">Cancelled</span>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center py-4">
                      No recent orders found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
