import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SellerContext } from "../Accounts/SellerContext";
import ProductsList from "../Products/ProductsList/ProductsList";
import { HiUser, HiChartBar, HiArchive, HiFolderAdd, HiCreditCard, HiShoppingCart, HiMenu , HiX } from "react-icons/hi";
import OrderManage from "./OrderManage";
import LoadingPage from "../../../LoadingPage";
import Dashboard from "./SideBarPages/Dashboard";
import Billing from "./SideBarPages/Billing";
import Profile from "./SideBarPages/Profile";
import AddProduct from "../Products/AddProducts/AddProduct";

const SellerDashboard = () => {
  const { seller, logout } = useContext(SellerContext);
  const [selectedSection, setSelectedSection] = useState("orders");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

const toggleSidebar = () => {
  setIsSidebarOpen(!isSidebarOpen);
};


  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Auto-hide sidebar for mobile
      } else {
        setIsSidebarOpen(true); // Show sidebar for larger screens
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Set initial state
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (seller) {
      console.log("Seller Data in Dashboard", seller);
    }
  }, [seller]);

  if (!seller) {
    return <LoadingPage />;
  }

  const handleLogout = () => {
    logout();
    navigate("/SellerLoginPage");
  };

  const renderContent = () => {
    switch (selectedSection) {
      case "profile":
        return <Profile />;
      case "orders":
        return <OrderManage />;
      case "dashboard":
        return <Dashboard />;
      case "billing":
        return <Billing />;
      case "addProduct":
        return <AddProduct />;
      case "products":
        return <ProductsList seller={seller.seller} />;
      default:
        return <div>Select a section</div>;
    }
  };

  const handleSectionSelect = (section) => {
    setSelectedSection(section);
    if (window.innerWidth < 768) {
      setIsSidebarOpen(false); // Auto-hide sidebar on selection for mobile
    }
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <aside
        className={`w-64 bg-white shadow-lg fixed top-0 left-0 h-screen transition-transform duration-300 z-20 ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="p-6">
          <h1 className="text-2xl font-bold text-gray-800">Seller Dashboard</h1>
        </div>
        <nav className="mt-6">
          <ul>
            <li
              className={`p-3 flex items-center space-x-4 cursor-pointer hover:bg-gray-100 ${
                selectedSection === "dashboard" ? "bg-gray-100" : ""
              }`}
              onClick={() => handleSectionSelect("dashboard")}
            >
              <HiArchive className="text-xl text-blue-600" />
              <span className="text-gray-700 font-medium">Dashboard</span>
            </li>
            <li
              className={`p-3 flex items-center space-x-4 cursor-pointer hover:bg-gray-100 ${
                selectedSection === "profile" ? "bg-gray-100" : ""
              }`}
              onClick={() => handleSectionSelect("profile")}
            >
              <HiUser className="text-xl text-blue-600" />
              <span className="text-gray-700 font-medium">Profile</span>
            </li>
            <li
              className={`p-3 flex items-center space-x-4 cursor-pointer hover:bg-gray-100 ${
                selectedSection === "billing" ? "bg-gray-100" : ""
              }`}
              onClick={() => handleSectionSelect("billing")}
            >
              <HiCreditCard className="text-xl text-blue-600" />
              <span className="text-gray-700 font-medium">Billing</span>
            </li>
            <li
              className={`p-3 flex items-center space-x-4 cursor-pointer hover:bg-gray-100 ${
                selectedSection === "orders" ? "bg-gray-100" : ""
              }`}
              onClick={() => handleSectionSelect("orders")}
            >
              <HiChartBar className="text-xl text-blue-600" />
              <span className="text-gray-700 font-medium">Orders</span>
            </li>
            <li
              className={`p-3 flex items-center space-x-4 cursor-pointer hover:bg-gray-100 ${
                selectedSection === "addProduct" ? "bg-gray-100" : ""
              }`}
              onClick={() => handleSectionSelect("addProduct")}
            >
              <HiFolderAdd className="text-xl text-blue-600" />
              <span className="text-gray-700 font-medium">Add Product</span>
            </li>
            <li
              className={`p-3 flex items-center space-x-4 cursor-pointer hover:bg-gray-100 ${
                selectedSection === "products" ? "bg-gray-100" : ""
              }`}
              onClick={() => handleSectionSelect("products")}
            >
              <HiShoppingCart className="text-xl text-blue-600" />
              <span className="text-gray-700 font-medium">My Stock</span>
            </li>
          </ul>
        </nav>
      </aside>

      {/* Main Content */}
      <main
        className={`flex-1 transition-all duration-300 ${isSidebarOpen ? "ml-64" : "ml-0"}`}
      >
        {/* Fixed Header */}
        <header
          className={`bg-blue-500 text-white p-4  shadow mb-6 fixed top-0 ${
            isSidebarOpen ? "left-64 w-[calc(100%-16rem)]" : "left-0 w-full"
          } z-10`}
        >
          <div className="flex justify-between items-center">
            <button onClick={toggleSidebar} className="text-white">
              {isSidebarOpen ? <HiX /> : <HiMenu  />}
            </button>
            <h2 className="text-lg font-bold">Seller Dashboard</h2>
            <button
              onClick={handleLogout}
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
            >
              Logout
            </button>
          </div>
        </header>

        {/* Scrollable Content */}
        <section className="p-6 pt-20 h-screen overflow-y-auto bg-gray-100">
          {renderContent()}
        </section>
      </main>
    </div>
  );
};

export default SellerDashboard;
