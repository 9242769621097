// import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";
import axios from "axios";
import { useNavigate } from "react-router-dom";




import React, { useState, useRef, useEffect } from "react";
import { FaRegHeart } from "react-icons/fa";
import { IoPersonOutline } from "react-icons/io5";
import { MdOutlineShoppingCart } from "react-icons/md";
// import { useNavigate } from "react-router-dom";
import categoryIcon from "../../assets/images/catmanue.png";
import { Link } from "react-router-dom";
import { categories } from "./categoryData";
import CartDropdown from "./CartDropdown";
import { useShoppingCart } from "../../Context/ShoppingCartContext";

// const CategoryBar = () => {
//   const [isCategoryOpen, setIsCategoryOpen] = useState(false);
//   const [isCartOpen, setIsCartOpen] = useState(false);
//   const [hoveredCategory, setHoveredCategory] = useState(null);
//   const [hoverPosition, setHoverPosition] = useState({ top: 0, left: 0 });
//   const categoryRef = useRef(null);
//   const navigate = useNavigate();
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const { cart, removeFromCart } = useShoppingCart();

//   useEffect(() => {
//     const loggedIn = localStorage.getItem("isLoggedIn");
//     setIsLoggedIn(loggedIn === "true");
//   }, []);

//   const handleMouseEnterIcon = () => {
//     setIsCategoryOpen(true);
//   };

//   const handleMouseLeaveIcon = () => {
//     setTimeout(() => {
//       if (categoryRef.current && !categoryRef.current.matches(":hover")) {
//         setIsCategoryOpen(false);
//         setHoveredCategory(null);
//       }
//     }, 200);
//   };

//   const handleMouseEnterCategory = (index, event) => {
//     const rect = event.target.getBoundingClientRect();
//     setHoverPosition({ top: rect.top - 65, left: rect.right - 20 });
//     setHoveredCategory(index);
//   };

//   const handleMouseLeaveCategory = () => {
//     setHoveredCategory(null);
//   };

//   const handleCategoryClick = (index) => {
//     const categoryName = categories[index].name;
//     navigate(`/category/${categoryName.replace(/\s+/g, "-").toLowerCase()}`);
//     setIsCategoryOpen(false);
//   };




const Search = () => {
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);


  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [hoverPosition, setHoverPosition] = useState({ top: 0, left: 0 });
  const categoryRef = useRef(null);
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { cart, removeFromCart } = useShoppingCart();

  useEffect(() => {
    const loggedIn = localStorage.getItem("isLoggedIn");
    setIsLoggedIn(loggedIn === "true");
  }, []);

  const handleMouseEnterIcon = () => {
    setIsCategoryOpen(true);
  };

  const handleMouseLeaveIcon = () => {
    setTimeout(() => {
      if (categoryRef.current && !categoryRef.current.matches(":hover")) {
        setIsCategoryOpen(false);
        setHoveredCategory(null);
      }
    }, 200);
  };

  const handleMouseEnterCategory = (index, event) => {
    const rect = event.target.getBoundingClientRect();
    setHoverPosition({ top: rect.top - 65, left: rect.right - 20 });
    setHoveredCategory(index);
  };

  const handleMouseLeaveCategory = () => {
    setHoveredCategory(null);
  };

  const handleCategoryClick = (index) => {
    const categoryName = categories[index].name;
    navigate(`/category/${categoryName.replace(/\s+/g, "-").toLowerCase()}`);
    setIsCategoryOpen(false);
  };




  const handleSearch = async (e) => {
    setQuery(e.target.value);
    if (e.target.value.length > 2) {
      try {
        const response = await axios.get(`http://39.61.51.195:8004/products/`);
        const filteredSuggestions = response.data.filter((product) =>
          product.name.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setSuggestions(filteredSuggestions);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    navigate(`/search/${suggestion.name}`);
    setQuery(suggestion.name);
    setSuggestions([]); // Clear the suggestions
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      navigate(`/search/${query}`);
    }
  };

  return (
    <>
      <div className="hidden sm:flex items-center justify-between p-2">

        <div className="relative">
          <input
            type="text"
            value={query}
            onChange={handleSearch}
            onKeyPress={handleKeyPress}
            className="bg-blue-500 text-white rounded-[10px] px-4 py-2 pl-10 w-[30rem] border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-white"
            placeholder="Search"
            plceholder
          />
          <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-white" />
          {suggestions.length > 0 && (
            <div className="absolute bg-white text-black w-full mt-2 rounded-md shadow-lg z-50">
              {suggestions.map((product) => (
                <div
                  key={product.id}
                  className="p-2 cursor-pointer hover:bg-gray-200"
                  onClick={() => handleSuggestionClick(product)}
                >
                  {product.name}
                </div>
              ))}
            </div>
          )}
        </div>
        {/* <div className="flex items-center space-x-10 hidden sm:flex">
          <div className="flex items-center">
            <IoPersonOutline className="text-2xl mr-2" />
            <Link to={isLoggedIn ? "/profile" : "/profile"}>
              <span className="text-lg cursor-pointer">
                {isLoggedIn ? "Profile" : "Profile"}
              </span>
            </Link>
          </div>
          <div className="flex items-center">
            <FaRegHeart className="text-2xl mr-2" />
            <span className="text-lg cursor-pointer">Favorites</span>
          </div>
          <div className="flex items-center relative">
            <MdOutlineShoppingCart
              className="text-2xl mr-2 cursor-pointer"
              onClick={() => setIsCartOpen(!isCartOpen)}
            />
            {cart.length > 0 && (
              <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs">
                {cart.length}
              </span>
            )}
            {isCartOpen && (
              <CartDropdown
                cart={cart}
                onClose={() => setIsCartOpen(false)}
                onRemove={removeFromCart}
              />
            )}
          </div>
        </div> */}
      </div>

    </>
  );
};

export default Search;
