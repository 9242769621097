import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { FaRegHeart, FaHeart, FaCartPlus } from "react-icons/fa";
import { AiOutlineEye } from "react-icons/ai";
import ProductDetails from "../DetailsPage/ProductDetails";
import LoadingPage from "../../../LoadingPage";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom

// Utility to capitalize first letters
const capitalizeFirstLetter = (string) => {
  return string.replace(/\b\w/g, (char) => char.toUpperCase());
};

const Products = () => {
  const [products, setProducts] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [cart, setCart] = useState([]);
  const [favorites, setFavorites] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [hoveredProductId, setHoveredProductId] = useState(null);

  const navigate = useNavigate(); // Initialize useNavigate hook for navigation

  // Fetch products
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`http://39.61.51.195:8004/products/`);
        setProducts(response.data);
      } catch (error) {
        setError("Error fetching products");
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  // Handlers
  const handleLoadMore = useCallback(() => {
    setShowAll(true);
  }, []);

  const handleFavoriteToggle = useCallback((id) => {
    setFavorites((prevFavorites) => ({
      ...prevFavorites,
      [id]: !prevFavorites[id],
    }));
  }, []);

  const handleAddToCart = useCallback((product) => {
    setCart((prevCart) => {
      const isAlreadyInCart = prevCart.some((item) => item.id === product.id);
      if (!isAlreadyInCart) {
        return [...prevCart, product];
      }
      return prevCart;
    });
    alert(`${product.name} added to cart!`);
  }, []);

  const handlePreview = useCallback((product) => {
    setSelectedProduct(product); // Trigger preview functionality
  }, []);

  const handleMouseEnter = useCallback((id) => {
    setHoveredProductId(id);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHoveredProductId(null);
  }, []);

  const handleClosePopup = useCallback(() => {
    setSelectedProduct(null);
  }, []);

  const displayedProducts = showAll ? products : products.slice(0, 16);

  // Navigate to the OnepageProduct page when image is clicked
  const handleImageClick = (productId) => {
    console.log("Navigating to product:", productId);
    navigate(`/products/${productId}`);
  };

  if (loading) return <LoadingPage />;
  if (error) return <p>{error}</p>;

  return (
    <>
     <div className="text-left ml-12 my-12">
        <h1 className="text-2xl font-bold text-gray-800">Feature Products</h1>
      </div>
    <div style={{ fontFamily: "Futura, sans-serif" }}>
      <div className="p-4 sm:p-6 ">
        <section className="col-span-9">
          {displayedProducts.length > 0 ? (
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 sm:gap-6">
              {displayedProducts.map((product) => (
                <div
                  key={product.id}
                  className="relative bg-white shadow-lg rounded-lg overflow-hidden cursor-pointer transform transition duration-300 hover:scale-105 hover:shadow-2xl group"
                  onMouseEnter={() => handleMouseEnter(product.id)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => handleImageClick(product.id)} // Navigate to OnepageProduct on image click
                >
                  {/* Product Image */}
                  <div className="relative w-full h-36 sm:h-48 md:h-56 flex items-center justify-center">
                    <img
                      src={
                        hoveredProductId === product.id && product.images.length > 1
                          ? product.images[1].image // Show second image on hover
                          : product.images.length > 0
                          ? product.images[0].image // Default to the first image
                          : "placeholder.jpg" // Fallback image
                      }
                      alt={product.name}
                      className="w-full h-full object-cover rounded-t-lg transition-all duration-300 ease-in-out"
                    />
                    {/* Hover Icons on Right Side */}
                    {hoveredProductId === product.id && (
                      <div className="absolute top-2 right-2 w-10 flex flex-col items-center space-y-4 bg-white p-2 rounded-lg shadow-lg">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleAddToCart(product);
                          }}
                          className="text-blue-600 hover:text-blue-800"
                        >
                          <FaCartPlus size={20} />
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePreview(product);
                          }}
                          className="text-blue-600 hover:text-blue-800"
                        >
                          <AiOutlineEye size={20} />
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleFavoriteToggle(product.id);
                          }}
                          className="text-red-500 hover:text-red-700"
                        >
                          {favorites[product.id] ? <FaHeart /> : <FaRegHeart />}
                        </button>
                      </div>
                    )}
                  </div>

                  {/* Product Details */}
                  <div className="p-3 sm:p-4">
                    <h2 className="font-semibold text-gray-800 line-clamp-1 text-sm sm:text-lg">
                      {capitalizeFirstLetter(product.name)}
                    </h2>
                    <div className="flex items-center justify-between mt-2">
                      <p className="text-gray-800 font-bold text-sm sm:text-lg">
                        Rs. {product.price}
                      </p>
                      <p className="text-gray-500 line-through text-xs sm:text-sm">
                        {(product.price * 1.05).toFixed(2)}
                      </p>
                    </div>
                    {hoveredProductId === product.id && (
                      <div className="text-center mt-3">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePreview(product);
                          }}
                          className="w-full bg-blue-600 text-white font-semibold py-2 rounded-lg hover:bg-blue-700 transition duration-300"
                        >
                          Preview
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>No products found.</p>
          )}
          {!showAll && products.length > 8 && (
            <div className="flex justify-center mt-6">
              <button
                onClick={handleLoadMore}
                className="px-4 sm:px-6 py-2 sm:py-3 text-sm sm:text-base text-blue-600 border border-blue-600 rounded-lg hover:bg-blue-600 hover:text-white transition-colors duration-300"
              >
                LOAD MORE
              </button>
            </div>
          )}
        </section>
      </div>

      {selectedProduct && (
        <ProductDetails product={selectedProduct} onClose={handleClosePopup} />
      )}
    </div>
    </>
  );
};

export default Products;
