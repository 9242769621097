import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { useShoppingCart } from "../../Context/ShoppingCartContext";
import ProductDetails from "../Products/DetailsPage/ProductDetails";
import LoadingPage from "../../LoadingPage";

const capitalizeFirstLetter = (string) => {
  return string.replace(/\b\w/g, (char) => char.toUpperCase());
};

const SearchResults = () => {
  const { query } = useParams();
  const [products, setProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [favorites, setFavorites] = useState({});
  const { addToCart, notification } = useShoppingCart();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`http://39.61.51.195:8004/products/`);
        setAllProducts(response.data);
        const filteredProducts = response.data.filter((product) =>
          product.name.toLowerCase().includes(query.toLowerCase())
        );
        setProducts(filteredProducts);
      } catch (error) {
        setError("Error fetching products");
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [query]);

  const handleLoadMore = useCallback(() => {
    setShowAll(true);
  }, []);

  const handleFavoriteToggle = useCallback((id) => {
    setFavorites((prevFavorites) => ({
      ...prevFavorites,
      [id]: !prevFavorites[id],
    }));
  }, []);

  const handleProductClick = useCallback((product) => {
    setSelectedProduct(product);
  }, []);

  const handleClosePopup = useCallback(() => {
    setSelectedProduct(null);
  }, []);

  const displayedProducts = showAll
    ? allProducts
    : products.length
    ? products
    : allProducts;

  if (loading) return <LoadingPage />;
  if (error) return <p>{error}</p>;

  return (
    <>
      <div className="container mx-auto p-4 mt-2 ml-12 mr-12">
        <h1 className="text-2xl mb-4">Search Results for "{query}"</h1>
        {displayedProducts.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
            {displayedProducts.map((product) => (
              <div
                key={product.id}
                className="border p-4 rounded-md shadow-lg hover:shadow-2xl transition-shadow"
              >
                <div className="w-full h-60 flex items-center justify-center">
                  <img
                    src={
                      product.images.length > 0
                        ? product.images[0].image
                        : "placeholder.jpg" // Fallback to a placeholder if no image exists
                    }
                    alt={product.name}
                    className="w-full h-full object-cover"
                    onClick={() => handleProductClick(product)}
                  />
                </div>
                <div className="flex justify-between items-center line-clamp-1 mb-2">
                  <h2 className="font-semibold text-gray-700 line-clamp-1">
                    {capitalizeFirstLetter(product.name)}
                  </h2>
                  <button
                    onClick={() => handleFavoriteToggle(product.id)}
                    className="text-red-500"
                  >
                    {favorites[product.id] ? <FaHeart /> : <FaRegHeart />}
                  </button>
                </div>
                <div className="flex mb-2">
                  {[...Array(product.rating || 5)].map((_, i) => (
                    <svg
                      key={i}
                      className="w-4 h-4 text-yellow-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 15l-5.5 3 2.2-5.4L2 7.6l5.8-.5L10 2l2.2 5.1 5.8.5-4.7 4.6L15.5 18z" />
                    </svg>
                  ))}
                </div>
                {/* <p className="text-gray-600 description">
                  {product.description}
                </p> */}
                <div className="flex items-center space-x-2 ">
                  <p className="text-gray-800 font-bold text-lg">
                    Rs. {product.price}
                  </p>
                  <p className="text-gray-500 line-through text-sm">
                    {(product.price * 1.05).toFixed(2)}
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No products found.</p>
        )}

        {!showAll && products.length > 0 && (
          <div className="flex justify-center mt-6">
            <button
              onClick={handleLoadMore}
              className="px-6 py-3 text-xl text-blue-600 border border-blue-600 rounded-lg hover:bg-blue-600 hover:text-white transition-colors duration-300"
            >
              Load More
            </button>
          </div>
        )}
      </div>

      {selectedProduct && (
        <ProductDetails product={selectedProduct} onClose={handleClosePopup} />
      )}

      {/* Styling to limit description to two lines */}
      <style>{`
        .description {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      `}</style>
    </>
  );
};

export default SearchResults;
