import React, { useContext } from "react";
import { SellerContext } from "../../Accounts/SellerContext";


const Dashboard = () => {
  const { totalOrder, totalAmount } = useContext(SellerContext);

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      

      {/* Main Content */}
      <main className="flex-1 p-6 space-y-6">
        {/* Header */}
        <header className="flex items-center justify-between">
          <h1 className="text-2xl font-semibold">Dashboard</h1>
          <div className="flex items-center space-x-4">
            <input
              type="text"
              placeholder="Search..."
              className="px-4 py-2 border rounded-lg text-sm"
            />
            <button className="p-2 bg-gray-200 rounded-full">
              <i className="fas fa-user"></i> {/* Replace with any icon library */}
            </button>
          </div>
        </header>

        {/* Stats Cards */}
        <section className="grid grid-cols-1 md:grid-cols-4 gap-6">
          <div className="p-4 bg-white rounded-lg shadow-md">
            <h3 className="text-sm text-gray-500">Today's Earning</h3>
            <p className="text-2xl font-bold">RS {totalAmount}/-</p>
          </div>
          <div className="p-4 bg-white rounded-lg shadow-md">
            <h3 className="text-sm text-gray-500">Total Orders</h3>
            <p className="text-2xl font-bold">{totalOrder}</p>
          </div>
          <div className="p-4 bg-white rounded-lg shadow-md">
            <h3 className="text-sm text-gray-500">New Clients</h3>
            <p className="text-2xl font-bold">+3,462</p>
          </div>
          <div className="p-4 bg-white rounded-lg shadow-md">
            <h3 className="text-sm text-gray-500">Sales</h3>
            <p className="text-2xl font-bold">$103,430</p>
          </div>
        </section>

        {/* Charts Section */}
        <section className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="p-6 bg-white rounded-lg shadow-md">
            <h3 className="text-lg font-semibold">Sales Overview</h3>
            {/* Add chart library integration or placeholder here */}
            <div className="mt-4 h-48 bg-gray-200 rounded-lg"></div>
          </div>
          <div className="p-6 bg-white rounded-lg shadow-md">
            <h3 className="text-lg font-semibold">Active Users</h3>
            <div className="mt-4 flex justify-between">
              <div>
                <p className="text-sm text-gray-500">Users</p>
                <p className="text-xl font-bold">36K</p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Clicks</p>
                <p className="text-xl font-bold">2M</p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Dashboard;
