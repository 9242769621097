import React, { useContext, useEffect, useState } from "react";
import { SellerContext } from "../Accounts/SellerContext";
import LoadingPage from "../../../LoadingPage"; // Assume you have a loading page component
import OrderDetailModal from "./OrderDetailModal"; // Modal for updating order

const Orders = () => {
  const { seller, totalOrder, settotalOrder, totalAmount, settotalAmount } =
    useContext(SellerContext);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [productMap, setProductMap] = useState({});
  const [statusOptions] = useState([
    { label: "Processing", value: "processing" },
    { label: "Pending", value: "pending" },
    { label: "Delivered", value: "delivered" },
    { label: "Cancelled", value: "cancelled" },
  ]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        // Fetch orders
        const response = await fetch(
          "http://39.61.51.195:8004/account/create-order/"
        );
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const data = await response.json();

        // Fetch seller's products
        const responseProducts = await fetch(
          `http://39.61.51.195:8004/products`
        );
        if (!responseProducts.ok)
          throw new Error(`HTTP error! status: ${responseProducts.status}`);
        const productsData = await responseProducts.json();

        // Create product map (id -> image)
        const productMapping = {};
        productsData.forEach((product) => {
          productMapping[product.id] = product.images.length > 0
            ? product.images[0].image
            : null;
        });
        setProductMap(productMapping);

        // Filter orders related to seller's products
        const filteredProducts = productsData.filter(
          (product) => String(product.seller_id) === String(seller.seller.id)
        );
        const filteredOrders = data.filter((order) =>
          filteredProducts.some((product) => product.id === order.product)
        );

        setOrders(filteredOrders);

        // Calculate total orders and total amount
        const totalOrders = filteredOrders.length;
        const totalOrderAmount = filteredOrders.reduce(
          (sum, order) => sum + parseFloat(order.total_price || 0),
          0
        );

        // Update context values
        settotalOrder(totalOrders);
        settotalAmount(totalOrderAmount);
      } catch (error) {
        console.error("Error fetching orders:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (seller) fetchOrders();
  }, [seller, settotalOrder, settotalAmount]);

  const handleOrderClick = (order) => setSelectedOrder(order);
  const handleCloseModal = () => setSelectedOrder(null);

  if (loading) return <LoadingPage />;
  if (error) return <p>Error fetching orders: {error}</p>;

  return (
    <div className="h-screen flex flex-col bg-gray-100">
      <div className="container mx-auto p-4 flex-grow">
        <h2 className="text-2xl font-bold mb-6">Order Management</h2>

        {/* Total Earnings and Orders */}
        <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
          <div className="p-4 bg-white rounded-lg shadow-md">
            <h3 className="text-sm text-gray-500">Today's Earnings</h3>
            <p className="text-2xl font-bold">RS {totalAmount}/-</p>
          </div>
          <div className="p-4 bg-white rounded-lg shadow-md">
            <h3 className="text-sm text-gray-500">Total Orders</h3>
            <p className="text-2xl font-bold">{totalOrder}</p>
          </div>
        </section>

        {/* Orders Table */}
        <div className="bg-white rounded-lg shadow-md overflow-x-auto">
          <table className="min-w-[800px] table-auto w-full text-left">
            <thead>
              <tr className="bg-gray-200 text-gray-700 text-sm uppercase">
                <th className="py-3 px-4">Order ID</th>
                <th className="py-3 px-4">User Name</th>
                <th className="py-3 px-4">Phone</th>
                <th className="py-3 px-4">Address</th>
                <th className="py-3 px-4">Product</th>
                <th className="py-3 px-4">Status</th>
                <th className="py-3 px-4">Qty</th>
                <th className="py-3 px-4">Total Price</th>
                <th className="py-3 px-4">Action</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr
                  key={order.id}
                  className="hover:bg-gray-50 border-b border-gray-200"
                >
                  <td className="py-3 px-4">{order.id}</td>
                  <td className="py-3 px-4">{order.name}</td>
                  <td className="py-3 px-4">{order.card_number}</td>
                  <td className="py-3 px-4">{order.address}</td>
                  <td className="py-3 px-4">
                    {productMap[order.product] ? (
                      <img
                        src={productMap[order.product]}
                        alt={`Product ${order.product}`}
                        className="w-10 h-10 rounded-lg"
                      />
                    ) : (
                      "No Image"
                    )}
                  </td>
                  <td className="py-3 px-4">
                    <span
                      className={`px-3 py-1 text-xs rounded ${
                        order.order_status === "delivered"
                          ? "bg-green-100 text-green-700"
                          : order.order_status === "cancelled"
                          ? "bg-red-100 text-red-700"
                          : order.order_status === "pending"
                          ? "bg-blue-100 text-blue-700"
                          : "bg-yellow-100 text-yellow-700"
                      }`}
                    >
                      {order.order_status}
                    </span>
                  </td>
                  <td className="py-3 px-4">{order.ordered_item}</td>
                  <td className="py-3 px-4">{order.total_price}</td>
                  <td className="py-3 px-4">
                    <button
                      onClick={() => handleOrderClick(order)}
                      className="text-blue-600 hover:underline"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Modal for Updating Order */}
        {selectedOrder && (
          <OrderDetailModal
            order={selectedOrder}
            onClose={handleCloseModal}
            statusOptions={statusOptions}
          />
        )}
      </div>
    </div>
  );
};

export default Orders;
