import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import categoryIcon from "../../assets/images/catmanue.png";

const CategorieBar = () => {
  const [categories, setCategories] = useState([]);
  const [isCategoryVisible, setIsCategoryVisible] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null); // Reference for the dropdown menu

  useEffect(() => {
    // Fetch categories from the API
    fetch("http://39.61.51.195:8004/account/category/")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const categoriesWithImages = data.map((category) => ({
          ...category,
          imageUrl: category.image || "default_image.jpg", // Fallback image
        }));
        setCategories(categoriesWithImages);
      })
      .catch((error) => console.error("Error fetching categories:", error));
  }, []);

  useEffect(() => {
    // Auto-hide categories on scroll
    const handleScroll = () => {
      if (isCategoryVisible) {
        setIsCategoryVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isCategoryVisible]);

  // Hide category dropdown if user clicks outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsCategoryVisible(false);
      }
    };

    // Add event listener for clicks outside the dropdown
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCategoryClick = (categoryId, categoryName) => {
    navigate(`/cat/${categoryId}`); // Navigate to category products page
    setIsCategoryVisible(false); // Hide the dropdown after clicking a category
  };

  return (
    <div className="w-full bg-blue-500 text-white shadow-md">
      <div className="container mx-auto flex items-center justify-between px-4 h-[70px] sm:px-2">
        {/* Categories Button */}
        <div className="flex items-center space-x-2">
          <button
            className="flex items-center space-x-2 px-4 py-2 rounded-md shadow font-medium"
            onClick={() => setIsCategoryVisible(!isCategoryVisible)}
          >
            <img src={categoryIcon} alt="Category Icon" className="mr-3" />
            <span className="text-lg text-white">Categories</span>
          </button>
        </div>

        {/* Horizontal Category Links */}
        <div className="hidden sm:flex space-x-4">
          {categories.map((category) => (
            <button
              key={category.category_id}
              className="hover:underline font-medium"
              onClick={() =>
                handleCategoryClick(category.category_id, category.name)
              }
            >
              {category.name}
            </button>
          ))}
        </div>
      </div>

      {/* Dropdown Menu for Categories */}
      {isCategoryVisible && (
        <div
          ref={dropdownRef}
          className="absolute left-10 top-[130px] bg-white text-gray-800 shadow-lg w-64 z-50 sm:w-full sm:left-0 sm:top-[70px] lg:w-[350px] lg:left-[0] lg:top-[80px]"
        >
          <ul className="divide-y divide-gray-200">
            {categories.map((category) => (
              <li
                key={category.category_id}
                className="flex items-center space-x-4 p-4 hover:bg-gray-100 cursor-pointer"
                onClick={() =>
                  handleCategoryClick(category.category_id, category.name)
                }
              >
                <img
                  src={category.imageUrl}
                  alt={category.name}
                  className="w-12 h-12 object-cover rounded-full border border-gray-300 shadow-sm"
                />
                <span className="font-medium">{category.name}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CategorieBar;
