import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import HomePage from "./Components/HomePage/HomePage";
import About from "./Components/Pages/About/About";
import Privacy from "./Components/Pages/BottomBarPages/Privacy";
import Term from "./Components/Pages/BottomBarPages/Term";
import ContactUs from "./Components/Pages/BottomBarPages/ContactUs";
import UserLoginPage from "./Components/UsersAccounts/Accounts/UserLoginPage";
import UserSignupPage from "./Components/UsersAccounts/Accounts/UserSignupPage";
import SellerLoginPage from "./Components/SellerAccounts/Accounts/SellerLoginPage";
import ForgotPassword from "./Components/SellerAccounts/Accounts/ForgotPassword";
import SellerSignupPage from "./Components/SellerAccounts/Accounts/SellerSignupPage";
import SellerDashboard from "./Components/SellerAccounts/SellerDashboard/SellerDashboard";
import UserDashboard from "./Components/UsersAccounts/Dashboard/UserDashboard";
import AddProduct from "./Components/SellerAccounts/Products/AddProducts/AddProduct";
import { SellerProvider } from "./Components/SellerAccounts/Accounts/SellerContext";
import SearchResults from "./Components/Navbar/SearchResults";
import CreateCategory from "./Components/SellerAccounts/Products/CreateCategory/CreateCategory";

import CategoryProduct from "./Components/Navbar/CategoryBarProducts";
import CategoryBarProduct from "./Components/Navbar/CategoryBarProducts";
import OnepageProduct from "../src/Components/Products/DetailsPage/OnepageProduct";


import Profile from "./Components/UsersAccounts/Profile/Profile";
import { ShoppingCartProvider } from "./Context/ShoppingCartContext";
import Navbar from "./Components/Navbar/Navbar";
import CreateOrderPage from "./Components/Pages/Products/Oders/CreateOrderPage";
import { OrderProvider } from "./Components/Pages/Products/Oders/OrderContext";
import Products from "./Components/Products/ForYou/Products";
import CheckOut from "./Components/Navbar/CheckOut";
import NotFound from "./NotFound";
import LoadingPage from "./LoadingPage";
import ScrollToTop from "./ScrollToTop";
import ProductDetails from "./Components/Navbar/ProductDetails";
// import OnepageProduct from "./Components/Products/DetailsPage/OnepageProduct";
import GoogleProfile from "./Components/UsersAccounts/Profile/GoogleProfile";
import CategoryProducts from "./Components/Pages/Products/CategoryProducts";
import { UserProvider } from "./Components/UsersAccounts/Accounts/UserContext";

const Layout = ({ children }) => {
  const location = useLocation();

  // Define the routes where the Navbar should be hidden
  const hideNavbarRoutes = [
    "/SellerLoginPage",
    "/seller-signup",
    "/ForgotPassword",
    "/seller-dashboard",
    "/create-category",
    "/add-products",
  ];

  const shouldHideNavbar = hideNavbarRoutes.includes(location.pathname);

  return (
    <>
      {!shouldHideNavbar && (
        <>
          <Navbar />
        </>
      )}
      {children}
    </>
  );
};

const App = () => {
  return (
    <>
      <div className="w-full overflow-x-hidden">

        <UserProvider>
          <OrderProvider>
            <Router>
              <ScrollToTop />
              <ShoppingCartProvider>
                <SellerProvider>
                  <Layout>
                    <Routes>
                      <Route path="/" element={<HomePage />} />
                      <Route path="/search/:query" element={<SearchResults />} />
                      <Route path="/product/:id" component={<ProductDetails />} /> {/* New Product Details Route */}
                      {/* chnage hamid */}
                      {/* <Route path="/product/:id" component={<OnepageProduct/>} /> */}
                      <Route path="/products/:productId" element={<OnepageProduct />} />
                      <Route path="/about" element={<About />} />
                      <Route path="/privacy" element={<Privacy />} />
                      <Route path="/term" element={<Term />} />
                      <Route path="/contact-us" element={<ContactUs />} />
                      <Route path="/products" element={<Products />} />
                      {/* User Accounts URLS */}
                      <Route path="/userLogin" element={<UserLoginPage />} />
                      <Route path="/userReg" element={<UserSignupPage />} />
                      <Route path="/userdashboard" element={<UserDashboard />} />
                      <Route path="/createOrder" element={<CreateOrderPage />} />

                      <Route path="/products/:categoryId" element={<CategoryProducts />} />
                      {/* <Route path="/cat-pro" element={<CategoryProduct />} /> */}
                      <Route path="/category/:categoryId" element={<CategoryProducts />} />
                      <Route path="/cat/:categoryId" element={<CategoryBarProduct />} />

                      <Route path="/checkout" element={<CheckOut />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/google-login-profile" element={<GoogleProfile />} />
                      {/* <Route path="/products/:productId" element={<OnepageProduct />} /> */}
                      {/* Loading Page */}
                      <Route
                        path="/loading"
                        element={<LoadingPage />}
                      />

                      {/* Seller Accounts URLS */}
                      <Route
                        path="/SellerLoginPage"
                        element={<SellerLoginPage />}
                      />
                      <Route path="/seller-signup" element={<SellerSignupPage />} />
                      <Route path="/ForgotPassword" element={<ForgotPassword />} />
                      <Route
                        path="/seller-dashboard"
                        element={<SellerDashboard />}
                      />
                      <Route path="/create-category" element={<CreateCategory />} />
                      <Route path="/add-products" element={<AddProduct />} />

                      {/* Catch all undefined routes */}
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </Layout>
                </SellerProvider>
              </ShoppingCartProvider>
            </Router>
          </OrderProvider>
        </UserProvider>
      </div>
    </>
  );
};


export default App;
