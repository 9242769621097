import React, { useEffect, useRef } from "react";
import { FaTimes } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

const CartDropdown = ({ cart, onClose, onRemove, setCart }) => {
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  // Close cart dropdown if clicked outside of it or on scroll
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onClose(); // Close the cart if the click is outside the dropdown
      }
    };

    const handleScroll = () => {
      onClose(); // Close the cart on scroll
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [onClose]);

  const handleCheckout = () => {
    // setCart(""); 
    navigate("/checkout"); // Redirect to checkout page
  };

  return (
    <div
      ref={dropdownRef}
      className="fixed right-24 mt-2 w-80 bg-white shadow-lg rounded-lg z-50"
      style={{ top: "3rem" }}
    >
      <div className="p-4 relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <FaTimes />
        </button>
        {cart.length > 0 ? (
          <>
            <h3 className="font-bold mb-4 text-gray-800">Cart Items</h3>
            <ul className="max-h-48 overflow-y-auto">
              {cart.map((item, index) => (
                <li
                  key={index}
                  className="flex justify-between items-center mb-4 p-2 border-b-4"
                >
                  <img
                    src={
                      item.images.length > 0
                        ? item.images[0].image
                        : "placeholder.jpg" // Fallback to a placeholder if the second image doesn't exist
                    }
                    alt={item.name}
                    className="w-16 h-16 object-cover rounded mr-4"
                  />
                  <div className="flex-1">
                    <h4 className="text-sm text-gray-800 font-semibold">
                      {item.name}
                    </h4>
                    <p className="text-sm text-gray-600">${item.price}</p>
                  </div>
                  <button
                    onClick={() => onRemove(index)}
                    className="text-red-500 hover:text-red-700 ml-2"
                  >
                    <FaTimes />
                  </button>
                </li>
              ))}
            </ul>
            <button
              onClick={handleCheckout}
              className="bg-blue-500 text-white font-bold py-2 px-4 rounded mt-4 w-full hover:bg-blue-700"
            >
              Checkout
            </button>
          </>
        ) : (
          <p className="text-gray-600 text-center">Your cart is empty</p>
        )}
      </div>
    </div>
  );
};

export default CartDropdown;
